$(document).ready(function () {
		$('#puCollegesListing,#dayCareListing').slick({
				nextArrow: '<i class="fa fa-angle-right nextArrowBtn"></i>',
				prevArrow: '<i class="fa fa-angle-left prevArrowBtn"></i>',
				slidesToShow: 4,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 2000,
				responsive: [
						{breakpoint: 1024, settings: {slidesToShow: 4, slidesToScroll: 1, infinite: true}},
						{breakpoint: 992, settings: {slidesToShow: 3, slidesToScroll: 1}},
						{breakpoint: 769, settings: {slidesToShow: 2, slidesToScroll: 1}},
						{breakpoint: 480, settings: {slidesToShow: 2, slidesToScroll: 1}}
				]
		});
		sliderSearchSchoolTypeSelect();
		openWishlistSidePopup();

	/*const heroSection = document.querySelector('.hero-section');
	// Set the actual image URL based on breakpoints
	const xxlBreakpoint = window.matchMedia("(min-width: 1440px)").matches;
	const xxlAboveBreakpoint = window.matchMedia("(max-width: 1440px)").matches;
	const mdBreakpoint = window.matchMedia("(max-width: 768px)").matches;
	const smBreakpoint = window.matchMedia("(max-width: 576px)").matches;

	if (xxlBreakpoint) {
		heroSection.style.backgroundImage = 'url("./../../new-ui/templates/home-page/img/hero-bg-big.jpg")';
	}
	if (xxlAboveBreakpoint) {
		heroSection.style.backgroundImage = 'url("./../../new-ui/templates/home-page/img/hero-bg.png")';
	}
	if (mdBreakpoint) {
		heroSection.style.backgroundImage = 'url("./../../new-ui/templates/home-page/img/hero-bg-tab.jpg")';
	}
	if (smBreakpoint) {
		heroSection.style.backgroundImage = 'url("./../../new-ui/templates/home-page/img/hero-bg-mobile.jpg")';
	}*/
});

$('.filter-tag').click(function () {
    $(this).toggleClass('active');
});

/*School Type Dropdown Slider */

$('body').on('click', '.updateHomeToggleSchoolType a',function(e){
		e.preventDefault();
		let currentSchoolType = $(this);
		currentSchoolType.addClass('active').siblings().removeClass('active');
		currentSchoolType.parent().prev().text(currentSchoolType.text());
		let schoolType = currentSchoolType.data('school-type');
		(schoolType==3) ? showBoardingFilters(schoolType) : hideBoardingFilters(schoolType);
		(schoolType==6) ? showOnlineSchoolFilters(schoolType):hideOnlineSchoolFilters(schoolType);
});

function hideOnlineSchoolFilters(schoolType) {
		if(schoolType != 3 && schoolType != 6)
			showSearchInputBox();
		$('#OnlineSchoolFilters').hide();
}

function showOnlineSchoolFilters(schoolType) {
		if(schoolType == 3 || schoolType == 6)
				hideSearchInputBox();
		$('#OnlineSchoolFilters').show();
}

function hideBoardingFilters(schoolType) {
		if(schoolType != 3 && schoolType != 6)
				showSearchInputBox();
		$('#BoardingFilters').hide();
}

function showBoardingFilters(schoolType) {
		if(schoolType == 3 || schoolType == 6)
				hideSearchInputBox();
		$('#BoardingFilters').show();
}

//Search Map Suggestion with autocomplete
var search_input_slider = document.getElementById('sliderSearchInput');
var search_option_slider = {
    componentRestrictions: {country: 'in'},
    //fields: ["name", "address_component", "formatted_address", "geometry.location"]
    fields: ["place_id"]
};
// var search_autocomplete_slider = new google.maps.places.Autocomplete(search_input_slider, search_option_slider);
var search_autocomplete_slider = new CustomAutoCompleteMaps(search_input_slider);
var searched_place_slider = '';
CustomAutoCompleteMaps.addListener(search_autocomplete_slider, 'place_changed', () => {
		searched_place_slider = search_autocomplete_slider.getPlace();
});
// google.maps.event.addListener(search_autocomplete_slider, 'place_changed', () => {
//     //searched_place_slider = search_autocomplete_slider.getPlace();
//     let getPlaceInfo = search_autocomplete_slider.getPlace();
//     let placeId = getPlaceInfo.place_id;
//     console.log("Place Id", placeId);
//     geocoder.geocode({'placeId': placeId}, (result, status) => {
//         console.log("result", result);
//         console.log("status", status);
//         if (status === 'OK') {
//             searched_place_slider = result[0];
//         }
//     });
//     placeChangedDebugApiCallAnalytics();
// });

$('#sliderSearchButton').click(function (e) {
    e.preventDefault();

    //school type slider field
    let school_type = $('#homeSchoolType a.active').data('school-type');
    let school_type_text = getSchoolTypeSearchText(school_type);
    if (school_type_text === undefined) {
        launchErrorToast('Please select school type');
        return false;
    }
    //manual trigger filter search if any filter is already selected
    if (parseInt(school_type) === 3) {
        if ($(".filter-tag.active").length != 0) {
            filterBoardingSearch();
            return true;
        }
    }

    let place_name = search_input_slider.value;
    let city2 = '';
    if (searched_place_slider !== "") {
        city2 = searched_place_slider.name;
    }

    if ((place_name === '' && city2 === undefined) || searched_place_slider === '') {
        launchErrorToast('Please type location');
        return false;
    }

    // home search analytics function
    HomeSearchAnalytics(school_type_text);

    let place_level = searched_place_slider.address_components[0].types[0];
    let place_lat = searched_place_slider.geometry.location.lat();
    let place_lng = searched_place_slider.geometry.location.lng();

    let formatted_address = searched_place_slider.formatted_address;
    formatted_address = slugify(formatted_address);

    // Insert Search History Code

    let searchPageName = (typeof (pageType) != "undefined") ? pageType : window.location.href;
    let SearchText = school_type_text + ' in ' + formatted_address;

	let cityText = '';

		let placeLevel = '';
		let localityObj = [];
		let administrativeAreaLevel1 = [];
		let administrativeAreaLevel2 = [];
		let city_2_name = '';
		let state_name = "";
		let locality = '';
		let filteredFormattedAddressData = filterGoogleMapApiResponse(searched_place_slider);
		let filteredFormattedAddressDataStr = filteredFormattedAddressDataSlug(searched_place_slider);

		if(parseInt(school_type) !== 3){
				localityObj = searched_place_slider.address_components.filter((v, i) => {
						if(v.types[0] === 'locality') return v;
				});

				administrativeAreaLevel1 = searched_place_slider.address_components.filter((v, i) => {
						// console.log(v.types[0]);
						if(v.types[0] === 'administrative_area_level_1') return v;
				});

				administrativeAreaLevel2 = searched_place_slider.address_components.filter((v, i) => {
						// console.log(v.types[0]);
						if(v.types[0] === 'administrative_area_level_2') return v;
				});

				if(localityObj.length) {
						// cityText = localityObj[0].long_name.toLowerCase();
						// placeLevel = localityObj[0].types[0];
						cityText = filteredFormattedAddressData.city.toLowerCase();
				}

				if(filteredFormattedAddressData.hasOwnProperty('locality')){
						if(!!filteredFormattedAddressData.locality && filteredFormattedAddressData.locality.length){
								placeLevel = localityObj[0].types[0];
								locality = filteredFormattedAddressData.locality[filteredFormattedAddressData.locality.length - 1];
						}
				}

				if(administrativeAreaLevel2.length){
						city_2_name = slugify(administrativeAreaLevel2[0].long_name.toLowerCase());
						// if(!placeLevel) placeLevel = administrativeAreaLevel2[0].types[0];
				}


				if(!!administrativeAreaLevel1.length){
						// state_name = slugify(administrativeAreaLevel1[0].long_name);
						state_name = slugify(filteredFormattedAddressData.state);
						if(!placeLevel && !cityText) placeLevel = administrativeAreaLevel1[0].types[0];
				}

				/* check if city name like state name */
				if(!!cityText){
						let checkCityAsStateExist = checkCityExistsInStates(cityText);
						if(checkCityAsStateExist.length){
								cityText = '';
								city_2_name = '';
								if(!!administrativeAreaLevel1.length) placeLevel = administrativeAreaLevel1[0].types[0];
						}
				}

				if(pageType !== "SEO"){
						city_2_name = '';
				}

				let searchSessionData = {
						'locality' : localityObj,
						'administrativeAreaLevel1' : administrativeAreaLevel1,
						'administrativeAreaLevel2' : administrativeAreaLevel2,
						'city_name' : cityText,
						'city_2_name' : city_2_name,
						'state_name' : state_name,
						'formattedAddress': formatted_address,
						'latitude' : place_lat,
						'longitude': place_lng,
						'placeLevel': placeLevel,
						'searchPlaceLocationData': searched_place_slider
				};

				localStorage.setItem('searchSessionData', JSON.stringify(searchSessionData));
				sessionStorage.removeItem('homepageSearchSession');
		}

    /*for (let i = 0; i < searched_place_slider.address_components.length; i++) {
				const addr = searched_place_slider.address_components[i];
				if (addr.types[0] == ['locality']) cityText = addr.long_name;
    }*/

    let InsertSearchParameter = {
        'page': searchPageName,
        'city': cityText,
        'filter': '',
        'filter_text': SearchText,
        'type_id': school_type,
        'location_short': city2,
        'location_long': formatted_address,
        'lat': place_lat,
        'long': place_lng,
    };

    InsertSearchApi(InsertSearchParameter);

    /* INSERT SEARCH HISTORY CODE END */

		let redirectUrl = '';
		/*if(placeLevel.length) redirectUrl +=`place_lavel=${placeLevel}`;
		if(cityText.length) redirectUrl += (placeLevel.length ? '&' : '') + `city_name=${slugify(cityText)}`;
		if(locality.length) redirectUrl += (cityText.length ? '&' : '') + `locality=${slugify(locality)}`;
		if((cityText !== city_2_name) && city_2_name.length) redirectUrl += (cityText.length ? '&' : '') + `city_2_name=${slugify(city_2_name)}`;
		if((!cityText && !city_2_name) && state_name.length) redirectUrl += `&state_name=${slugify(state_name)}`;*/
		redirectUrl+=filteredFormattedAddressDataStr;



		/**
		 * *********Range Max********
		 *  Day & PU :   30 in case City || 5  in case Locality
		 *  PreSchool : 10 in case City || 5  in case Locality
		 *  Boarding : 100 in case Locality || City Both
		 *  Start
		 * */

		let localityStr = getCleanLocality(formatted_address,cityText,city_2_name,state_name);

		let distanceRangeMax = '';
		if( [1, 5].includes(parseInt(school_type)) && localityStr){ // update range slider in case of city id
				distanceRangeMax = 5;
		}
		if(!!cityText.length && [1, 5].includes(parseInt(school_type)) && !localityStr){ // update range slider in case of city id
				distanceRangeMax = 30;
		}

		if( [2].includes(parseInt(school_type)) && localityStr){ // update range slider in case of city id
				distanceRangeMax = 5;
		}
		if(!!cityText.length && [2].includes(parseInt(school_type)) && !localityStr){ // update range slider in case of city id
				distanceRangeMax = 10;
		}

		if( [3].includes(parseInt(school_type)) && localityStr){ // update range slider in case of city id
				distanceRangeMax = 100;
		}
		if(!!cityText.length && [3].includes(parseInt(school_type))){ // update range slider in case of city id
				distanceRangeMax = 100;
		}

		if(distanceRangeMax) redirectUrl += `&range_max=${distanceRangeMax}`;
		/**
		 * *********Range Max*******
		 * END
		 **/



    $.ajax({
        url: HOST + '/home/encode_decode.php',
        data: {
            latitude: place_lat,
            longitude: place_lng
        },
        async: true,
        error: function () {
            alert("Something went wrong!");
        },
        success: function (response) {
            response = JSON.parse(response);
						window.location.href = `${HOST}/search/${school_type_text}/${formatted_address.toLowerCase()}-${response.data.latlng}${redirectUrl.length? '/?' + redirectUrl : ''}`;
        },
        type: 'GET'
    });
});

//Boarding Filter Search

$("button.btn.btn-filter-search").click(function (e) {

		var schooltype = $('#homeSchoolType a.active').data('school-type');

		e.preventDefault();

		sessionStorage.setItem('homepageSearchSession', true);
		sessionStorage.removeItem('addBoardingLocation');

		if ($(".filter-tag.active").length == 0) {
				launchErrorToast('Please select any filter');
				return false;
		}
		localStorage.setItem('searched_school_type', schooltype);
		if(schooltype==3) {
				filterBoardingSearch();
		}
		if(schooltype==6){
				filterOnlineSchoolSearch();
		}
});


function filterBoardingSearch() {
    // home search analytics function
    HomeSearchAnalytics('Boarding School with Filter Search');
    var posttags = '';
    var board = [];
    var region = [];
    var coed = [];
    var insertTagsHistory = [];

    $(".filter-tag.active").each(function () {
				let id;
				let tag;
				tag = $(this).attr('data-id');
        insertTagsHistory.push(tag);

        if (tag.indexOf('board') === 0) {
            id = tag.split("-")[1];
            board.push(get_board_name(id));

        } else if (tag.indexOf('region') === 0) {
						id = tag.split("-")[1];
						region.push(get_region_name(id));

        } else if (tag.indexOf('coed') === 0) {
            id = tag.split("-")[1];
            coed.push(get_coed_name(id));
        } else {
            posttags += '&Tags[]=' + tag;
        }


    }).promise().done(function () {

        if (posttags.length > 0) {
            posttags = posttags.indexOf('&') === 0 ? "?" + posttags.substring(1) : "?" + posttags;
        }

        // Insert Search History Code start
        let InsertSearchParameter = {
            'page': (typeof (pageType) != "undefined") ? pageType : window.location.href,
            'city': '',
            'filter': insertTagsHistory.join(','),
            'filter_text': '',
            'type_id': 3,
            'location_short': '',
            'location_long': '',
            'lat': '',
            'long': '',
        };
        InsertSearchApi(InsertSearchParameter);

        // Insert Search History Code end


        var path = "";

        if (region.length > 0) {
            path = coed.join("-and-");
            path = (path.length > 0) ? path + "-" + board.join("-and-") : board.join("-and-");
            path = (path.length > 0) ? path + "-schools-in-" + region.join("-and-") + "-region" : "schools-in-" + region.join("-and-") + "-region";

        } else if (coed.length > 0 && board.length > 0) {
            path = coed.join("-and-") + "-" + board.join("-and-") + "-schools";
        } else if (coed.length > 0) {
            path = coed.join("-and-") + "-schools";
        } else if (board.length > 0) {
            path = board.join("-and-") + "-schools";
        }

        path = (path.length > 0) ? path + "/" : "";
        if(DEBUG) console.log(HOST + '/search/boarding-school/' + path + posttags);
        window.location.href = HOST + '/search/boarding-school/' + path + posttags;
        return false;
    });
}

function filterOnlineSchoolSearch() {
		// home search analytics function
		HomeSearchAnalytics('Online School with Filter Search');
		let posttags = '';
		const board = [];
		const region = [];
		const coed = [];
		const insertTagsHistory = [];

		$(".filter-tag.active").each(function () {
				let id;
				let tag;
				tag = $(this).attr('data-id');
				insertTagsHistory.push(tag);

				if (tag.indexOf('board') === 0) {
						id = tag.split("-")[1];
						board.push(get_board_name(id));

				} else if (tag.indexOf('region') === 0) {
						id = tag.split("-")[1];
						region.push(get_region_name(id));

				} else if (tag.indexOf('coed') === 0) {
						id = tag.split("-")[1];
						coed.push(get_coed_name(id));
				} else {
						posttags += '&Tags[]=' + tag;
				}


		}).promise().done(function () {

				if (posttags.length > 0) {
						posttags = posttags.indexOf('&') === 0 ? "?" + posttags.substring(1) : "?" + posttags;
				}

				// Insert Search History Code start
				let InsertSearchParameter = {
						'page': (typeof (pageType) != "undefined") ? pageType : window.location.href,
						'city': '',
						'filter': insertTagsHistory.join(','),
						'filter_text': '',
						'type_id': 6,
						'location_short': '',
						'location_long': '',
						'lat': '',
						'long': '',
				};
				InsertSearchApi(InsertSearchParameter);

				// Insert Search History Code end


				var path = "";

				if (region.length > 0) {
						path = coed.join("-and-");
						path = (path.length > 0) ? path + "-" + board.join("-and-") : board.join("-and-");
						path = (path.length > 0) ? path + "-schools-in-" + region.join("-and-") + "-region" : "schools-in-" + region.join("-and-") + "-region";

				} else if (coed.length > 0 && board.length > 0) {
						path = coed.join("-and-") + "-" + board.join("-and-") + "-schools";
				} else if (coed.length > 0) {
						path = coed.join("-and-") + "-schools";
				} else if (board.length > 0) {
						path = board.join("-and-") + "-schools";
				}

				path = (path.length > 0) ? path + "/" : "";
				if(DEBUG) console.log(HOST + '/search/online-school/' + path + posttags);
				window.location.href = HOST + '/search/online-school/' + path + posttags;
				return false;
		});
}


function getSchoolTypeSearchText($schoolType) {
		$schoolType = parseInt($schoolType);
		if ($schoolType === 1) {
				return 'day-school';
		} else if ($schoolType === 2) {
				return 'pre-school';
		} else if ($schoolType === 3) {
				return 'boarding-school';
		} else if ($schoolType === 5) {
				return 'pu-junior-colleges';
		} else if ($schoolType === 4) {
				return 'day-care';
		} else if ($schoolType === 6) {
				return 'online-school';
		}
}

function slugify(string) {
		const a = 'Ã Ã¡Ã¤Ã¢Ã£Ã¥ÄƒÃ¦Ä…Ã§Ä‡ÄÄ‘ÄÃ¨Ã©Ä›Ä—Ã«ÃªÄ™ÄŸÇµá¸§Ã¬Ã­Ã¯Ã®Ä¯Å‚á¸¿Ç¹Å„ÅˆÃ±Ã²Ã³Ã¶Ã´Å“Ã¸á¹•Å•Å™ÃŸÅŸÅ›Å¡È™Å¥È›Ã¹ÃºÃ¼Ã»Ç˜Å¯Å±Å«Å³áºƒáºÃ¿Ã½ÅºÅ¾Å¼Â·/_,:;';
		const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
		const p = new RegExp(a.split('').join('|'), 'g');

		return string.toString().toLowerCase()
				.replace(/\s+/g, '-') // Replace spaces with -
				.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
				.replace(/&/g, '-and-') // Replace & with 'and'
				.replace(/[^\w\-]+/g, '') // Remove all non-word characters
				.replace(/\-\-+/g, '-') // Replace multiple - with single -
				.replace(/^-+/, '') // Trim - from start of text
				.replace(/-+$/, '') // Trim - from end of text
}

function get_board_name(id) {

		let board = "";
		switch (id) {
        case "1":
            board = "cbse";
            break;
        case "2":
            board = "icse-isc";
            break;
        case "3":
            board = "ib";
            break;
        case "4":
            board = "Others";
            break;
        case "6":
            board = "igcse";
            break;
    }

    return board;
}

function get_region_name(id) {

		let region = "";
		switch (id) {
        case "1":
            region = "north";
            break;
        case "2":
            region = "east";
            break;
        case "3":
            region = "south";
            break;
        case "4":
            region = "west";
            break;
        case "5":
            region = "central";
            break;
    }

    return region;
}

function get_coed_name(id) {

		let coed = "";
		switch (id) {
        case "1":
            coed = "coed";
            break;
        case "2":
            coed = "girls";
            break;
        case "3":
            coed = "boys";
            break;
    }
    return coed;
}

function kFormatter(n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K+";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M+";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B+";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T+";
}


// TODO: temporary closed
// function getStatsCount() {
// 		$.get(`${HOST}/home-page/server/api/stats-count.php`).done(stats => statsCountUpdate(stats));
// }

// function statsCountUpdate(stats) {
// 		$('#count .school-list').html(kFormatter(stats.schools_listed));
// 		$('#count .school-viewed').html(kFormatter(stats.schools_viewed));
// 		$('#count .login-parent').html(kFormatter(stats.parents_served));
// }

// getStatsCount();
// checkHeaderSearch();

// $(window).on('scroll', function () {
//     checkHeaderSearch();
// });

// function checkHeaderSearch() {
// 		return true;
//     // let scrollPos = $(window).scrollTop();
//     // if (scrollPos === 0) {
//     //     $('.mobileSearchFormBlock').hide();
//     //     $('.desktopSearchHeader').hide();
//     //     return;
//     // }
//     // let visible = $('#Slider').isInViewport(); // checking element is in viewport
//     // let mobile_tablet = mobileAndTabletCheck();  // checking mobile and tablet browser
//     // if (visible) {
//     //     if (mobile_tablet) {
//     //         $('.mobileSearchFormBlock').hide();
//     //     } else {
//     //         $('.desktopSearchHeader').hide();
//     //         $('.profileToggle').css('width', '100%');
//     //     }
//     // } else {
//     //     if (mobile_tablet) {
//     //         $('.mobileSearchFormBlock').css('display', 'inline-flex');
//     //     } else {
//     //         $('.desktopSearchHeader').css('display', 'inline-flex');
//     //         $('.profileToggle').css('width', 'unset');
//     //     }
//     // }
// }

// document.body.addEventListener("click", function (evt) {
//     let target = $(evt.target);
//     let DeskcheckedProp = $('#SliderSchoolType').prop('checked');
//
//     if (target && target[0] && target[0].className === 'dd-button h40' && DeskcheckedProp) {
//         $('#SliderSchoolType').prop('checked', false);
//         evt.preventDefault();
//         return false;
//     }
//
//     if (target && target[0] && target[0].id && (target[0].id == 'SliderSchoolType')) {
//         return false;
//     }
//     if ($(target[0]).parent().parent().attr('id') === 'SearchSchoolTypeBlock') {
//         return false;
//     }
//     if (DeskcheckedProp) {
//         $('#SliderSchoolType').prop('checked', false);
//         return false;
//     }
// });

// unnecessary functions
function updateUserLocationFromPreferredModal() {
    let userLoc = $('#prefSearchTextField').val();
    userLoc = (userLoc.trim() === '') ? 'Location Unavailable' : userLoc;
    $('#userLocationField').html(userLoc);
}

function shortlistedOnLoadHomePage(data) {
    return false;
}

function sliderSearchSchoolTypeSelect() {
    let schoolType = localStorage.getItem('searched_school_type');
    $(".updateHomeToggleSchoolType").find("[data-school-type='" + schoolType + "']").trigger('click');
    // $('#SliderSchoolType').prop('checked', false);
}

function HomeSearchAnalytics(action) {
    let actionText = action.trim();
	gtag('event', {
		event_category: 'Home Search',
		event_action: actionText,
		event_label: actionText
	});
}

function HomeCategoryPageAnalytics(actionText) {
    //alert(actionText);
	gtag('event', {
		event_category: 'Home Category',
		event_action: actionText,
		event_label: actionText + "clicked"
	});
}

function hideSearchInputBox() {
				$('#home-search-btn').addClass('d-none');
				$('#home-search-input').addClass('d-none');
				$('.dropdown-toggle-home').css('margin-right','20px');
				$('#home-type-select').css('flex-basis','100%');
				$('#home-type-select').css('max-width','100%');
				$('#homeSchoolType').css('width','100%');
				$('.hero-search').css('margin','0 367px');
		if (window.matchMedia('(max-width: 992px)').matches) {
				$('.hero-search').css('margin','');
		}
}

function showSearchInputBox() {

		$('#home-search-btn').removeClass('d-none');
		$('#home-search-input').removeClass('d-none');
		$('.dropdown-toggle-home').css('margin-right','0px');
		$('#home-type-select').css('flex-basis','220px');
		$('#home-type-select').css('max-width','35%');
		$('#homeSchoolType').css('width','');
		$('.hero-search').css('margin','');
}

