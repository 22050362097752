/**	when location is not enabled then lat long  **/
const BlockedLat = $('#Blockedlat').val();
const BlockedLon = $('#Blockedlon').val();
const SCH_TYPE_ARR_FOR_HOME_LOAD = SCHOOL_TYPES.slice(0, 3).map(({id, schooltype}) => {
		return {id, text: schooltype.toLowerCase().replace(' school', '')};
});

$(document).ready(function () {
		getLandingLocation().then(r => DEBUG ? console.log({r}, 'getLandingLocation called.') : 1);
		getSmartSuggestion().then(r => DEBUG ? console.log({r}, 'getSmartSuggestion called.') : 1);
		loadPreviouslyVisitedSchools().then(r => DEBUG ? console.log({r}, 'loadPreviouslyVisitedSchools called.') : 1);
});

async function getSmartSuggestion() {
		try {
				loader_school('suggestion');
				await homepagePlaceholderCard('suggestion')
				const {data: schools = []} = await axios.get(`${HOST}/school-suggestions/schoolSuggestion.php`);
				if (schools.length > 0) {
						let html = await schoolListingHTML(schools);
						loader_school('suggestion', true);
						$('#suggestionSchoolListing').empty().html(html);
						await slickSchoolInit('#suggestionSchoolListing');
				} else $('#suggestionSchool').hide();
				return 1;
		} catch (error) {
				return error;
		}
}

async function loadPreviouslyVisitedSchools() {
		try {
				loader_school('recentlyViewed');
				await homepagePlaceholderCard('recentlyViewed');
				const {data: schools = []} = await axios.get(`${HOST}/home/ajax_prev_visited.php`);
				if (schools.length > 0) {
						let html = await schoolListingHTML(schools);
						loader_school('recentlyViewed', true);
						$('#recentlyViewedSchoolListing').empty().html(html);
						await slickSchoolInit('#recentlyViewedSchoolListing');

				} else $('#recentlyViewedSchool').hide();
				return 1;
		} catch (error) {
				return error;
		}
}

async function firstSchoolList(latitude, longitude, schoolTypeId, schoolType) {
		try {
				loader_school('firstSchoolList');
				await homepagePlaceholderCard(schoolType)
				const {data: schools = []} = await axios.get(`${HOST}/home-page/server/api/list-schools.php`,
						{params: {latitude, longitude, school_type_id: schoolTypeId}});
				if (schools.length > 0) {
						let html = await schoolListingHTML(schools);
						loader_school(schoolType, true);
						$(`#${schoolType}SchoolListing`).empty().html(html);
						await slickSchoolInit(`#${schoolType}SchoolListing`);
				} else $(`#${schoolType}School`).hide();
				return 1;
		} catch (error) {
				return error;
		}
}

async function schoolListingHTML(schools) {
		let schoolHTML = '';
		schools.forEach(({final_url, imgpthm, name, address1, address}) => {
				let schoolImage = (imgpthm).replace(/\s+/g, '').trim();
				schoolHTML += `<div class="col-md-3" type="button" onclick="window.open('${final_url}', '_blank');">
																<div class="d-flex flex-column school-view-card">
																		<img data-src="${schoolImage}" class="school-img lazyload" alt="${name}"/>
																		<h3 class="px-3 text-capitalize">${name}</h3>
																		<p class="px-3">${address1 ? address1 : address}</p>
																</div>													
												</div>`;
		})
		return schoolHTML;
}

async function slickSchoolInit(elementId) {
		$(elementId).not('.slick-initialized').slick({
				slidesToShow: 4,
				slidesToScroll: 1,
				infinite: false,
				autoplay: true,
				autoplaySpeed: 8000,
				responsive: [
						{breakpoint: 1024, settings: {slidesToShow: 4, slidesToScroll: 1, infinite: true}},
						{breakpoint: 992, settings: {slidesToShow: 3, slidesToScroll: 1}},
						{breakpoint: 769, settings: {slidesToShow: 2, slidesToScroll: 1}},
						{breakpoint: 600, settings: {slidesToShow: 2, slidesToScroll: 1}},
						{breakpoint: 480, settings: {slidesToShow: 1, slidesToScroll: 1}},
						{breakpoint: 360, settings: {slidesToShow: 1, slidesToScroll: 1}},
						{breakpoint: 320, settings: {slidesToShow: 1, slidesToScroll: 1}}
				]
		});
}

async function isShowPosition(position) {
	gtag('event', {
		event_category: 'User_Permission',
		event_action: 'User_Allowed_Browser_Location',
		event_label: 'User_Clicked_Allow_Browser_Location'
	});

		const lat = position.coords.latitude;
		const lon = position.coords.longitude;

		if (DEBUG) console.log("user location ", {position});

		document.getElementById('cityLat').value = lat;
		document.getElementById('cityLng').value = lon;

		if (lat && lon) await loadSchoolList(lat, lon);
}

async function isShowError(error) {
	gtag('event', {
		event_category: 'User_Permission',
		event_action: 'User_Blocked_Browser_Location',
		event_label: 'User_Clicked_Block_Browser_Location'
	});
		//calling school list from blocked ip getting from ip geolocation.php

		await loadSchoolList(BlockedLat, BlockedLon);

		switch (error.code) {
				case error.PERMISSION_DENIED:
						break;
				case error.POSITION_UNAVAILABLE:
						break;
				case error.TIMEOUT:
						break;
				case error.UNKNOWN_ERROR:
						break;
		}
}

const loadSchoolList = async (lat, lon) => {
		return SCH_TYPE_ARR_FOR_HOME_LOAD.map(async ({id, text}) => await firstSchoolList(lat, lon, id, text));
}

async function getLandingLocation() {
		try {
				if (navigator.geolocation) {
						navigator.geolocation.getCurrentPosition(isShowPosition, isShowError,
								{maximumAge: 600000, timeout: 5000, enableHighAccuracy: true});
				} else alert("Geolocation is not supported by this browser.");
				return 1;
		} catch (error) {
				return error;
		}
}

async function homepagePlaceholderCard(id){
	let $schoolPlaceHolderCards = '';

	for (var i = 0; i < 4; i++) {
		$schoolPlaceHolderCards += '<div class="col-md-3 loading ' + (i > 0 ? 'd-none d-md-block' : 'd-block') + '" type="button">' +
			'<div class="d-flex flex-column school-view-card">' +
			'<div class="school-img-placeholder"></div>' +
			'<h3 class="px-3 text-capitalize placeholder-text"></h3>' +
			'<p class="px-3 placeholder-text"></p>' +
			'</div>' +
			'</div>';
	}

// Append the generated cards to a container element
	$(`#${id}SchoolListing`).html('<div class="row">' + $schoolPlaceHolderCards + '</div>');

}
